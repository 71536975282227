@import "../../../styles/vars";

.hero {
  padding-top: 134px;
  padding-bottom: 62px;
  text-align: center;
}
.hero__text-box {
  padding: 0 24px;
}
.hero__title {
  font-size: 56px;
  line-height: 1;
  letter-spacing: -2px;
  animation: start-animation 2s ease-out;
}

.hero__text, .hero__btn {
  margin-top: 24px;
  animation: start-animation 2s ease-out;
}
.hero__btn {
  margin-left: auto;
  margin-right: auto;
}
.hero__date-box {
  margin-top: 58px !important;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  animation: start-animation-right 2s ease-out;
}

.hero__img {
  animation: start-animation-left 2s ease-out;
  margin: 20px 12px 0;
}
@keyframes start-animation {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  75% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }

}
@keyframes start-animation-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  75% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes start-animation-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  85% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@media screen and (min-width: 820px) {
  .hero {
    padding-top: 148px;
    padding-bottom: 84px;
  }
  .hero__title {
    font-size: 72px;
    letter-spacing: 0;
  }
  .hero__text {
    margin-top: 16px;
  }
  .hero__btn {
    margin-top: 32px;
  }
  .hero__date-box {
    margin-top: 84px !important;
    padding-left: $margin-left-s;
    padding-right: 24px;
    display: grid;
    grid-template-columns: 3fr 2fr;
    text-align: left;
    gap: 75px;
  }
  .hero__img {
    margin-top: 16px;
    height: 278px;
  }
}
@media screen and (min-width: 1024px) {
  .hero__date-box {
    padding-left: $margin-left-m;
    padding-right: 84px;
    gap: 125px;
  }
}
@media screen and (min-width: 1440px) {
  .hero {
    padding-top: 210px;
    padding-bottom: 120px;
  }
  .hero__title {
    font-size: 110px;
  }
  .hero__text {
    margin-top: 24px;
  }
  .hero__btn {
    margin-top: 48px;
  }
  .hero__date-box {
    margin-top: 120px !important;
    padding-left: $margin-left-l;
    padding-right: 120px;
    gap: 168px;
  }
  .hero__img {
    margin-top: 24px;
    height: 392px;
  }
}
@media screen and (min-width: 1920px) {
  .hero {
    padding-top: 280px;
    padding-bottom: 160px;
  }
  .hero__title {
    font-size: 146px;
  }
  .hero__text {
    margin-top: 32px;
  }
  .hero__btn {
    margin-top: 64px;
  }
  .hero__date-box {
    margin-top: 160px !important;
    padding-left: $margin-left-xl;
    padding-right: 160px;
    gap: 225px;
  }
  .hero__img {
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
    height: 522px;
  }
}